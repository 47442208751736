.dashboardCounterBox {
  width: 100%;
  height: 200px;
  -webkit-box-shadow: 0px -1px 9px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px -1px 9px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px -1px 9px -3px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
  cursor: pointer;
}

.title-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.counter-title {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
}

.counter-num-box {
  width: 40px;
  height: 40px;
  background-color: black;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.nums {
  color: #ffffff;
  margin-top: 25%;
}
